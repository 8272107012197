<template>
	<div id="ag-grid-demo">
		<vx-card>
			<!-- TABLE ACTION ROW -->
			<ag-grid-vue
				:gridOptions="gridOptions"
				class="ag-theme-material w-100 mb-4 ag-grid-table"
				:columnDefs="columnDefs"
				:defaultColDef="defaultColDef"
				:rowModelType="rowModelType"
				serverSideStoreType="partial"
				rowSelection="multiple"
				:enableRangeSelection="true"
				:suppressCopyRowsToClipboard="true"
				colResizeDefault="shift"
				:suppressDragLeaveHidesColumns="true"
				:animateRows="false"
				:floatingFilter="true"
				:pagination="true"
				:paginationPageSize="paginationPageSize"
				:suppressPaginationPanel="true"
				:suppressRowClickSelection="true"
				:rowClassRules="rowClassRules"
				:statusBar="statusBar"
				:sideBar="sideBar"
				:cacheBlockSize="500"
				:rowHeight="38"
				:getContextMenuItems="getContextMenuItems"
				@grid-ready="onGridReady"
				@column-resized="onColumnResized">
			</ag-grid-vue>
			<div class="vx-row">
				<div class="vx-col w-full">
					<vs-pagination
					:total="totalPages"
					:max="maxPageNumbers"
					v-model="currentPage" />
				</div>
			</div>
		</vx-card>
	</div>
</template>

<script>
	import { AgGridVue } from "ag-grid-vue"
	import "ag-grid-enterprise";

	import '@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss'

	export default {
		components: {
			AgGridVue,
		},
		data() {
			return {
				gridOptions: {},
				gridCount: 0,
				maxPageNumbers: 7,
				gridApi: null,
				defaultColDef: {
					sortable: true,
					resizable: true,
					suppressMenu: true,
					suppressMovable: false,
					filterParams: {
						suppressAndOrCondition: true,
						newRowsAction: 'keep'
					},
				},
				rowClassRules: null,
				statusBar: null,
				sideBar: null,
				rowModelType: "serverSide",
				columnDefs: [
				{
					headerName: 'Domain',
					field: 'domain',
					minWidth: 275,
					filter: "agTextColumnFilter",
					pinned: 'left',
					cellRenderer: (data) => {
						if(data.value) {
							return `<a href="/search?domain_name=${encodeURIComponent(data.value)}" target="_blank">${data.value}</a>`
						}
						else {
							return '';
						}
					},
				},
				{
					headerName: 'TLD',
					field: 'tld',
					filter: "agSetColumnFilter",
					filterParams: {
						suppressAndOrCondition: true,
						newRowsAction: 'keep',
						values: ['site','website','store','online','tech','press','host','fun','space','uno','pw','in.net'],
						suppressMiniFilter: true,
					},
					minWidth: 100,
					width: 100,
				},
				{
					headerName: 'Registrar Organization',
					field: 'registrar_organization',
					filter: "agTextColumnFilter",
					minWidth: 275,
				},
				{
					headerName: 'Priority Score',
					field: 'priority_score',
					filter: "agTextColumnFilter",
					minWidth: 150,
				},
				{
					headerName: 'Category',
					field: 'category',
					filter: "agTextColumnFilter",
					minWidth: 275,
				},
				{
					headerName: 'Created At',
					field: 'created_at',
					filter: 'agMultiColumnFilter',
					filterParams: {
						filters: [
							{
								filter: 'agTextColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									newRowsAction: 'keep',
								}
							},
							{
								filter: 'agDateColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									defaultOption: 'inRange',
									newRowsAction: 'keep',
								}
							},
						],
					},
					minWidth: 235,
					cellRenderer: (data) => {
						if(data.value) {
							return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
						}
						else {
							return ''
						}
					},
					sort: 'desc',
				},
				{
					headerName: 'Updated At',
					field: 'updated_at',
					filter: 'agMultiColumnFilter',
					filterParams: {
						filters: [
							{
								filter: 'agTextColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									newRowsAction: 'keep',
								}
							},
							{
								filter: 'agDateColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									defaultOption: 'inRange',
									newRowsAction: 'keep',
								}
							},
						],
					},
					minWidth: 235,
					hide: true,
					cellRenderer: (data) => {
						if(data.value) {
							return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
						}
						else {
							return ''
						}
					}
				},
				],
			}
		},
		watch: {
			windowWidth(val) {
				// Toggle colummn movable
				this.toggleColumnMovable(val);
				// Toggle colummn pinning
				this.toggleColumnPinned(val);
			},
			totalPages() {
				if(this.gridApi) {
					this.gridCount = this.gridApi.getDisplayedRowCount() - 1
				}
				else {
					this.gridCount = 0
				}
			},
		},
		computed: {
			windowWidth: function(){
				return this.$store.state.windowWidth;
			},
			currentTimestamp: function(){
				return this.$store.getters.getCurrentTimestamp;
			},
			formattedTimestamp: function(){
				return this.currentTimestamp != null ? new Date(this.currentTimestamp) : this.currentTimestamp;
			},
			paginationPageSize() {
				if(this.gridApi) {
					return this.gridApi.paginationGetPageSize()
				}
				else {
					return 500
				}
			},
			totalPages() {
				if(this.gridApi) {
					return this.gridApi.paginationGetTotalPages()
				}
				else {
					return 0
				}
			},
			currentPage: {
				get() {
					if(this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
						else return 1
					},
				set(val) {
					this.gridApi.paginationGoToPage(val - 1);
				}
			},
		},
		methods: {
			toggleColumnMovable(val) {
				if(val <= 576) {
					this.defaultColDef['suppressMovable'] = true;
				}
				else {
					this.defaultColDef['suppressMovable'] = false;
				}
			},
			onColumnResized() {
				this.gridApi.resetRowHeights();
			},
			sizeColumnsToFit() {
				this.gridApi.sizeColumnsToFit();
			},
			onGridReady(params) {
				let self = this;

				const endpoint = `${this.$SERVERSIDE}/node-sources/spamhaus`;

				// Set Data Source
				params.api.setServerSideDatasource({
					getRows(params) {
						fetch(endpoint, {
							method: 'post',
							body: JSON.stringify(params.request),
							headers: {"Content-Type": "application/json; charset=utf-8"}
						})
						.then(httpResponse => httpResponse.json())
						.then(response => {
							if(response.lastRow) {
								// clear all overlays
								self.gridApi.hideOverlay();
								params.successCallback(response.rows, response.lastRow);
							}
							else {
								// clear all overlays
								self.gridApi.hideOverlay();
								// show 'no rows' overlay
								self.gridApi.showNoRowsOverlay();
								params.successCallback([], 0);
							}
						})
						.catch(error => {
							console.error(error);
							params.failCallback();
						})
					}
				});
			},
			getUrlParameter(name) {
				return this.$route.query[name];
			},
			filterAgGrid(model) {
				if(model) {
					this.gridApi.setFilterModel(model);
					this.gridApi.onFilterChanged();
				}
			},
			getContextMenuItems(params) {
				let result,
					self = this;

				if(params['column'] && params['column']['colId'] && params['column']['colId'] == 'domain') {
					result = [
						{
							name: "Resolve Domain(s)",
							action: function() {
								let rangeSelection = self.gridApi.getCellRanges(),
									value = params['value'];

								if(rangeSelection) {
									// Get selected cells & iterate through them to find domains
									rangeSelection.forEach(item => {
										let start = Math.min(item.startRow.rowIndex, item.endRow.rowIndex),
											end = Math.max(item.startRow.rowIndex, item.endRow.rowIndex),
											isRightClicked = false;
										
										for (let i = start; i <= end; i++) {
											if(self.gridApi.getDisplayedRowAtIndex(i)['data']['domain'] == value) {
												isRightClicked = true
											}
											window.open('https://anonymto.com/?http://' + self.gridApi.getDisplayedRowAtIndex(i)['data']['domain'], '_blank');
										}
										
										if(!isRightClicked) {
											window.open('https://anonymto.com/?http://' + value);
										}
									});
								}
								else {
									window.open('https://anonymto.com/?http://' + value);
								}
							},
						},
						'separator',
						'copy',
						'copyWithHeaders',
						'paste',
						'separator',
						'export'
					];
				}
				else {
					result = [
						'copy',
						'copyWithHeaders',
						'paste',
						'separator',
						'export'
					];
				}

				return result;
			}
		},
		beforeMount() {
			// Reset movable columns on mobile
			this.toggleColumnMovable(this.windowWidth);

			// Set Grid sidebar
			this.sideBar = {
				toolPanels: [
				{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel",
					toolPanelParams: {
						suppressRowGroups: true,
						suppressValues: true,
						suppressPivots: true,
						suppressPivotMode: true,
						suppressSideButtons: true,
						suppressColumnSelectAll: true,
						suppressColumnExpandAll: true
					}
				}
				],
			};
		},
		mounted() {
			this.gridApi = this.gridOptions.api;

			// Resize to fit columns
			this.sizeColumnsToFit();

			// Page Refresh Event
			this.$root.$on('refreshPage', () => {
				// Reset filters
				this.gridApi.setFilterModel(null);
				// Reset selection
				this.gridApi.deselectAll();
				// Reset sort
				this.gridApi.setSortModel([
					{
					colId: "created_at",
					sort: "desc"
					}
				]);
			});

			// Page Download Event
			this.$root.$on('downloadPage', () => {
				// AgGrid CSV Export
				this.gridApi.exportDataAsCsv()
			});

		},
		destroyed() {
			// Clear Listing Count
			this.$store.dispatch("clearListingCount");
			
			// Unbind Button Event Listeners
			this.$root.$off('refreshPage');
			this.$root.$off('downloadPage');
		}
	}

</script>